<template>
  <div class="status-404">
    <p class="code">404</p>
    <p class="code-detail">Oops! Page Not Found</p>
    <br />
    <br />
    <router-link class="w-100" to="/">
      <b-button class="w-25" variant="danger" type="button">Home</b-button>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "status-404",
  components: {}
};
</script>

<style scoped>
.status-404 {
  text-align: center;
  padding: 125px 0px;
}
p {
  margin-bottom: 0px;
}
.code {
  font-size: 3rem;
}
.code-detail {
  font-size: 1.5rem;
}
</style>
